import { h, FunctionalComponent } from 'preact';
import { useEffect, useRef, useCallback, useMemo, useState } from 'preact/hooks';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import { useSelector } from '../../hooks';
import { getCookie, isIOS } from '../../utils';
import * as loadState from '../../loadState';
import { setHlsJsLoadState } from '../../actions';
import styles from './styles.css';

interface ThumbnailPreviewProps {
    onClick: (progress: number) => void;
    onHoverChange: (show: boolean, progress: number) => void;
}

const maxLoopDuration = 5; // seconds

const getCTAText = (lang?: string) => {
    switch (lang) {
        case 'zh-cn':
            return '鼠标在视频上停留即可预览';
        case 'zh-tw':
            return '滑鼠在影片上停留即可預覽';
        default:
            return 'Keep hovering to play';
    }
};

const ThumbnailPreview: FunctionalComponent<ThumbnailPreviewProps> = (props) => {
    const { onClick, onHoverChange } = props;
    const [show, setShow] = useState(false);
    const [canPlay, setCanPlay] = useState(false);
    const [showText, setShowText] = useState(false);
    const videoData = useSelector((state) => state.videoData);
    const hlsLoadState = useSelector((state) => state.hlsJsLoadState);
    const videoRef = useRef<HTMLVideoElement>(null);
    const timeoutRef = useRef<any>(null);
    const useNativeHls = useMemo(() => isIOS(), []);
    const dispatch = useDispatch();

    // initialize Hls player
    useEffect(() => {
        if (useNativeHls && videoRef.current) {
            videoRef.current.src = videoData.hlsNoCaptions ?? '';
            return;
        }

        import('hls.js')
            .then(({ default: Hls }) => {
                if (hlsLoadState !== loadState.FINISHED) {
                    dispatch(setHlsJsLoadState(loadState.FINISHED as any));
                }

                if (!Hls.isSupported() && videoRef.current) {
                    // fallback to mp4 if hls.js isn't supported
                    videoRef.current.src = videoData.video174kMP4Url ?? '';
                    return;
                }

                const hls = new Hls({
                    capLevelToPlayerSize: true,
                    maxMaxBufferLength: maxLoopDuration
                });
                hls.loadSource(videoData.hlsNoCaptions ?? '');
                hls.attachMedia(videoRef.current as HTMLVideoElement);
            })
            .catch((err) => {
                dispatch(setHlsJsLoadState(loadState.FAILED as any));
                console.error('Failed to load hls.js', err);
            });
    }, [hlsLoadState, useNativeHls, videoData]);

    // calculate selected caption
    const selectedCaption = useMemo(() => {
        const captions = videoData.captionsVTT ?? [];
        let lang = getCookie('djvideolang') ?? '';
        if (lang.length === 0) {
            lang = 'English';
        }
        return captions.find((c) => c.label === lang);
    }, [videoData.captionsVTT]);

    // play/pause video whenever show prop changes
    useEffect(() => {
        if (show) {
            videoRef.current?.play().catch((err) => console.error('Thumbnail preview failed to play', err));
        } else {
            videoRef.current?.pause();
        }
        const progress = videoRef.current?.currentTime ?? 0;
        onHoverChange?.(show, Math.round(progress));
    }, [show]);

    const handleClick = useCallback(
        (evt: any) => {
            evt.stopPropagation(); // prevent thumbnail click handler from firing twice
            const videoEl = evt.target;
            const progress = parseInt(videoEl.currentTime ?? 0);
            videoEl.pause();
            videoEl.currentTime = 0;
            onClick(progress);
        },
        [onClick]
    );

    const handleTimeUpdate = useCallback((evt: any) => {
        const videoEl = evt.target;
        const progress = videoEl.currentTime;
        if (progress >= maxLoopDuration) {
            // reset the video after the max duration has been passed
            videoEl.currentTime = 0;
        }
    }, []);

    const handleStart = useCallback(() => {
        setShowText(true);
        timeoutRef.current = setTimeout(() => setShow(true), 750);
    }, []);

    const handleStop = useCallback(() => {
        setShowText(false);
        clearTimeout(timeoutRef.current);
        setShow(false);
    }, []);

    if (!useNativeHls && hlsLoadState !== loadState.FINISHED) {
        return null;
    }

    const showVideo = show && canPlay;
    return (
        <div
            className={styles.preview}
            onMouseEnter={handleStart}
            onTouchStart={handleStart}
            onTouchEnd={handleStop}
            onMouseLeave={handleStop}
        >
            <video
                ref={videoRef}
                className={cn('video-thumb-preview', { [styles.show]: showVideo })}
                muted
                loop
                onClick={handleClick}
                crossOrigin="anonymous"
                preload="none"
                playsInline
                onCanPlay={() => setCanPlay(true)}
                onLoadStart={() => setCanPlay(false)}
                onTimeUpdate={handleTimeUpdate}
            >
                {selectedCaption && (
                    <track
                        kind="captions"
                        src={selectedCaption.url}
                        srcLang={selectedCaption.lang}
                        label={selectedCaption.label}
                        default
                    />
                )}
            </video>
            <div className={cn(styles.hoverText, { [styles.show]: showText && !showVideo })}>
                {getCTAText(videoData.lang)}
            </div>
        </div>
    );
};

export default ThumbnailPreview;
