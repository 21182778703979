import { h, Fragment } from 'preact';
import { useSelector } from 'react-redux';
import { CircularProgressbar } from 'react-circular-progressbar';
import styles from './styles.css';
import { gTimeFormat } from '../../utils';

const AdCountdownCircle = (props) => {
    const currentAdPosition = useSelector((state) => state.currentAdPosition);
    const currentAdDuration = useSelector((state) => state.currentAdDuration);
    const width = useSelector((state) => state.width);

    return (
        <div className={styles.adCirlce}>
            <div className={styles.adText}>
                {gTimeFormat(Math.floor(currentAdDuration - currentAdPosition)).slice(1)}
            </div>
            <CircularProgressbar
                value={Math.floor((currentAdPosition / currentAdDuration) * 100)}
                counterClockwise={true}
            />
            <div className={styles.adContent} data-testid="video-ad-text">
                {width > 350 ? 'ADVERTISEMENT' : 'AD'}
            </div>
        </div>
    );
};

export default AdCountdownCircle;
