import { h, Component } from 'preact';
import ResponsiveThumbnail from '../ResponsiveThumbnail';
import styles from './styles.css';

class VideoRow extends Component {
    constructor(props) {
        super(props);
    }

    _renderCountdown() {
        const { duration, currentPosition } = this.props;
        const newCountdown = Math.max(Math.floor(duration) - Math.floor(currentPosition), 0);
        const unitText = newCountdown === 1 ? 'second' : 'seconds';
        return (
            <div className={styles.countdownText} data-testid="countdown">
                Starting in <strong>{newCountdown}</strong> {unitText}
            </div>
        );
    }

    render() {
        const { videoData, showCountdown, currentBreakpoint } = this.props;
        if (!videoData) {
            return null;
        }

        let thumbWidth = 220;
        if (currentBreakpoint === '8U') {
            thumbWidth = 150;
        } else if (currentBreakpoint === '4U') {
            thumbWidth = 120;
        } else if (currentBreakpoint === 'tiny') {
            thumbWidth = 70;
        }
        return (
            <div
                className={styles.row}
                onClick={this.props.onClick}
                tabIndex={0}
                onKeyUp={(e) => {
                    if (e.key === 'Enter') {
                        this.props.onClick();
                    }
                }}
            >
                <ResponsiveThumbnail
                    className={styles.thumbnail}
                    height={thumbWidth * 0.5625}
                    videoData={videoData}
                    width={thumbWidth}
                />
                <div className={styles.textContainer}>
                    <div className={styles.videoTitle}>{videoData.name}</div>
                    {showCountdown && this._renderCountdown()}
                </div>
            </div>
        );
    }
}

VideoRow.defaultProps = {
    showCountdown: false,
    onClick() {}
};

export default VideoRow;
