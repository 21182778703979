import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { showMoreVideosSlide, hideMoreVideosSlide } from '../../actions';
import Modal from '../Modal';
import { findAllVideos } from '../../video-api';

import styles from './styles.css';
import ResponsiveThumbnail from '../ResponsiveThumbnail';

const showSlidePercentage = 0.6; // show the slide after this percentage of the video
const hideAfterSeconds = 7; // hide the slide after this amount of seconds elapse

class MoreVideosSlide extends Component {
    constructor(props) {
        super(props);

        this.state = {
            recommendations: null
        };
        // making this an instance variable instead of state since it doesn't affect what's rendered
        this._hasShown = false;
        this._isLoading = false;
        this._positionShown = null;
        this._setThumbnailRef = this._setThumbnailRef.bind(this);
        this._handleSlideClick = this._handleSlideClick.bind(this);
        this._hideSlide = this._hideSlide.bind(this);
    }

    _setThumbnailRef(el) {
        this._thumbnailRef = el;
    }

    _hideSlide() {
        this.props.hideMoreVideosSlide();
        this._positionShown = null;
    }

    _handleSlideClick() {
        this._hideSlide();
        this.props.playVideo(this.state.recommendations.guid);
    }

    async _fetchRecommendedVideo() {
        const params = {
            fields: 'guid,thumbnailList,name,thumbnailImageManager,thumbnailURL',
            count: 20
        };

        if (this.props.videoData['wsj-section'] === 'Opinion') {
            params.groupid = '224';
        } else {
            (params.type = 'playlist'), (params.query = 'Most Viewed WSJ Videos');
        }

        const items = await findAllVideos(params);
        const i = items.findIndex((item) => item.guid === this.props.videoData.guid);
        if (i !== -1) {
            items.splice(i, 1);
        }
        return items[Math.floor(Math.random() * items.length)];
    }

    componentDidUpdate(prevProps) {
        const {
            width,
            isMoreVideosSlideVisible,
            currentPosition,
            duration,
            showMoreVideosSlide,
            isEndscreenVisible,
            videoData,
            isAdMode,
            isPlaybackInitializing,
            isStickyModeEnabled
        } = this.props;

        // We don't show this component when the player is smaller than 485px
        const hideSlide =
            width < 485 ||
            videoData?.state !== 'vod' ||
            (videoData?.doctypeID === '469' && (videoData?.column?.length ?? 0) === 0) ||
            isNaN(currentPosition) ||
            isNaN(duration) ||
            duration === 0;

        if (hideSlide) {
            if (isMoreVideosSlideVisible) {
                // hide slide if visible after resizing
                this._hideSlide();
            }
            return;
        }

        const { guid } = this.props.videoData;
        if (prevProps.videoData.guid !== guid && this._hasShown) {
            if (isMoreVideosSlideVisible) {
                // hide if still visible after video ends
                this._hideSlide();
            }

            // new video so reset state
            this._hasShown = false;
            this._positionShown = null;
            return;
        }

        // show slide 60% of the way through
        if (
            currentPosition / duration >= showSlidePercentage &&
            !isEndscreenVisible &&
            !isAdMode &&
            !isPlaybackInitializing &&
            !isMoreVideosSlideVisible &&
            !this._hasShown &&
            !this._isLoading &&
            !isStickyModeEnabled
        ) {
            this._isLoading = true;

            const { onSlideShowing } = this.props;
            this._fetchRecommendedVideo()
                .then((video) => {
                    this._hasShown = true;
                    this._isLoading = false;
                    if (video && !video.error) {
                        this.setState({ recommendations: video });
                        showMoreVideosSlide();
                        onSlideShowing();
                        this._positionShown = currentPosition;
                    }
                })
                .catch((err) => {
                    console.error('Failed to fetch recommendations', err);
                    this._hasShown = true;
                    this._isLoading = false;
                });
        }
        // check if we need to hide the slide after a certain amount of time or when the endscreen comes into view
        else if (
            this._positionShown !== null &&
            (this._positionShown + hideAfterSeconds <= currentPosition || isEndscreenVisible)
        ) {
            this._hideSlide();
        }
    }

    render() {
        const { isMoreVideosSlideVisible, isStickyModeEnabled } = this.props;
        let { recommendations } = this.state;
        if (!recommendations) {
            recommendations = {};
        }

        // We don't show this component when the player is smaller than 485px or is sticky mode
        const showSlide =
            isMoreVideosSlideVisible &&
            !isStickyModeEnabled &&
            this.props.width >= 485 &&
            this.props.videoData?.state === 'vod' &&
            (this.props.videoData?.doctypeID !== '469' || this.props.videoData?.column?.length > 0);
        const slideClasses = [styles.slide];
        if (showSlide) {
            slideClasses.push(styles.slideIn);
        }

        return (
            <Modal
                className={slideClasses.join(' ')}
                isVisible={showSlide}
                onClick={this._handleSlideClick}
                onClose={this._hideSlide}
            >
                {recommendations.thumbnailList && (
                    <ResponsiveThumbnail
                        className={styles.thumbnail}
                        height={this._thumbnailRef?.offsetWidth ?? 0 * 0.5625}
                        componentRef={this._setThumbnailRef}
                        videoData={recommendations}
                        width={this._thumbnailRef?.offsetWidth}
                    />
                )}
                <div className={styles.textContainer}>
                    <div className={styles.headerText}>You may also like</div>
                    <div className={styles.videoTitle}>{recommendations.name}</div>
                </div>
            </Modal>
        );
    }
}

function mapStateToProps(reduxState) {
    return {
        currentPosition: reduxState.currentPosition,
        videoData: reduxState.videoData,
        duration: reduxState.duration,
        isMoreVideosSlideVisible: reduxState.isMoreVideosSlideVisible,
        width: reduxState.width,
        isEndscreenVisible: reduxState.isEndscreenVisible,
        isAdMode: reduxState.isAdMode,
        isPlaybackInitializing: reduxState.isPlaybackInitializing,
        isStickyModeEnabled: reduxState.isStickyModeEnabled
    };
}

const ConnectedMoreVideosSlide = connect(mapStateToProps, { showMoreVideosSlide, hideMoreVideosSlide })(
    MoreVideosSlide
);

export default ConnectedMoreVideosSlide;
export const UnconnectedMoreVideosSlide = MoreVideosSlide;
