import { useEffect } from 'preact/hooks';
import { useSelector as useReduxSelector, TypedUseSelectorHook } from 'react-redux';
import { State } from '../store';
import { createMark } from '../utils';

export const useSelector: TypedUseSelectorHook<State> = useReduxSelector;

/**
 * Hook that executes performance.mark when the component is mounted
 */
export const useMarkMounted = (name: string) => {
    useEffect(() => createMark(name), []);
};
