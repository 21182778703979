import { fetchRaw, fetchJson } from '@video/common/dist/lib/fetch';
import { getSaveApiByDomain } from './utils';

const saveApi = getSaveApiByDomain();
const timeout = 2000;

/**
 * @typedef {Object} SaveContentInput
 * @property {string} contentId
 * @property {string} pub
 * @property {'audio' | 'video'} contentType
 * @param {SaveContentInput} body
 * @returns {Promise<void>}
 */
export function saveContent(body) {
    if (saveApi === null) return Promise.reject(new Error('Invalid save api'));
    return fetchRaw(saveApi, {
        method: 'POST',
        body: JSON.stringify({ ...body, contentType: 'video' }),
        headers: { 'content-type': 'application/json' },
        credentials: 'include'
    }).then((res) => {
        if (!res.ok) throw new Error('Failed to save video');
    });
}

/**
 * @param {string} guid
 * @param {string} pub
 * @returns {Promise<void>}
 */
export function deleteContent(guid, pub) {
    if (saveApi === null) return Promise.reject(new Error('Invalid save api'));
    return fetchRaw(`${saveApi}?id=${guid}&pub=${pub}`, {
        method: 'DELETE',
        headers: { 'content-type': 'application/json' },
        credentials: 'include'
    }).then((res) => {
        if (!res.ok) throw new Error('Failed to delete video');
    });
}

/**
 * @param {string} id
 * @param {string} pub
 * @returns {Promise<boolean>}
 */
export function isContentSaved(id, pub) {
    if (saveApi === null) return Promise.reject(new Error('Invalid save api'));
    return fetchJson(`${saveApi}/${id}/count?pub=${pub}`, { credentials: 'include', timeout }).then(
        ({ count }) => count > 0
    );
}

/**
 * @param {string} id
 * @param {string} pub
 * @param {RequestInit} [init]
 * @returns {Promise<{ progress: number; }>}
 */
export function getProgress(id, pub, init = {}) {
    if (saveApi === null) return Promise.reject(new Error('Invalid save api'));
    return fetchJson(`${saveApi}/${id}/progress?pub=${pub}`, { ...init, credentials: 'include', timeout });
}

/**
 * @param {string} id
 * @param {string} pub
 * @param {number} progress
 * @returns {Promise<void>}
 */
export function saveProgress(id, pub, progress) {
    if (saveApi === null) return Promise.reject(new Error('Invalid save api'));
    return fetchRaw(`${saveApi}/progress`, {
        method: 'POST',
        body: JSON.stringify({ contentId: id, pub, progress }),
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
        keepalive: true
    }).then((res) => {
        if (!res.ok) throw new Error('Failed to save progress');
    });
}
