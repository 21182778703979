import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { showEndscreen, hideEndscreen, replacePlaylist } from '../../actions';
import * as utils from '../../utils';
import Modal from '../Modal';
import VideoRow from './VideoRow';

import styles from './styles.css';

class Endscreen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            visiblePlaylist: []
        };

        this._isLoading = false;
        this._hasShown = false;
        this._hadError = false;
        this._hideTime = -1;
        this._handleFadeOut = this._handleFadeOut.bind(this);
    }

    _syncVisiblePlaylist() {
        // check if the playlist has been updated and update local state
        if (this.state.visiblePlaylist !== this.props.playlist && this.props.playlist.length > 0) {
            this.setState({ visiblePlaylist: this.props.playlist });
        }
    }

    _handleFadeOut() {
        // only update playlist when the endscreen has faded out
        this._syncVisiblePlaylist();
    }

    componentDidUpdate(prevProps) {
        const { currentPosition, duration, videoData } = this.props;
        if (
            isNaN(currentPosition) ||
            isNaN(duration) ||
            duration === 0 ||
            videoData?.state !== 'vod' ||
            (videoData?.doctypeID === '469' && (videoData?.column?.length ?? 0) === 0)
        ) {
            return;
        }

        const { isEndscreenVisible, hideEndscreen } = this.props;
        if (prevProps.videoData.guid !== videoData.guid) {
            // hide if still visible after video ends
            if (isEndscreenVisible) {
                this._hideTime = Date.now();
                hideEndscreen();
            }
            this._hasShown = false;
            return;
        }

        const { showEndscreen, replacePlaylist, playlist, onEndsceenShown } = this.props;
        // start loading new playlist if needed with 20 seconds remaining
        if (
            !isEndscreenVisible &&
            !this._isLoading &&
            playlist.length === 0 &&
            Math.floor(currentPosition) > Math.max(Math.floor(duration) - 20, 0) &&
            !this._hadError
        ) {
            this._isLoading = true;
            const { videoApiUrl, fields, playerSettings } = this.props;
            utils
                .getNewPlaylist(videoApiUrl, playerSettings, videoData, fields)
                .then((playlist) => {
                    if (!playlist || (playlist.length === 1 && playlist?.[0]?.guid === videoData.guid)) {
                        this._hadError = true;
                        this._isLoading = false;
                    } else {
                        replacePlaylist(playlist);
                        this.setState({ visiblePlaylist: playlist }, () => (this._isLoading = false)); // signal isLoading after setState completes so we don't fetch the playlist multiple times
                    }
                })
                .catch((err) => {
                    console.error('Failed to load playlist for endscreen', err);
                    this._isLoading = false;
                });
        }

        const shouldShow =
            !isEndscreenVisible && // not currently visible
            !this._isLoading && // not loading a new playlist
            playlist.length > 0 && // there are videos to display
            !this._hasShown && // hasn't shown for this video
            Math.floor(currentPosition) > Math.max(Math.floor(duration) - 7, 0) && // 7 seconds remaining
            // has been at least 2 seconds since last hidden.
            // Prevents a race condition where Redux doesn't update this component when the click handler takes awhile
            Date.now() - 2000 > this._hideTime;

        if (shouldShow) {
            // make sure the visible playlist is updated before displaying
            onEndsceenShown();
            this._syncVisiblePlaylist();
            this._hasShown = true;
            showEndscreen();
        }
    }

    render() {
        const classes = [styles.container];
        const { currentPosition, isEndscreenVisible, isControlsVisible, width, videoData, duration } = this.props;

        const isVisible =
            isEndscreenVisible &&
            duration > 0 &&
            videoData?.state === 'vod' &&
            Math.floor(currentPosition) > Math.max(Math.floor(duration) - 7, 0) && // 7 seconds remaining
            (videoData?.doctypeID !== '469' || videoData?.column?.length > 0);

        if (isVisible) {
            classes.push(styles.fadeIn);
        }

        if (isControlsVisible) {
            classes.push(styles.controlsVisible);
        }

        if (width < 440) {
            classes.push(styles.fullscreen);
        }

        const modalProps = {
            className: classes.join(' '),
            isVisible
        };

        const { currentBreakpoint, playVideo } = this.props;

        // we need to store the playlist in local state so we can control when that updates.
        // otherwise the rows might display new data before the components are completely faded out
        const playlist = this.state.visiblePlaylist;
        if (playlist === null) return;
        const showVideoTwo = currentBreakpoint === '16U' || currentBreakpoint === 'full';
        const commonVideoRowProps = { currentBreakpoint };
        return (
            <Modal
                tabIndex={isEndscreenVisible ? 0 : -1}
                style={{ display: isEndscreenVisible ? 'block' : 'none' }}
                {...modalProps}
                onFadeOut={this._handleFadeOut}
                onClose={this.props.hideEndscreen}
            >
                <div className={styles.headerText}>Up Next</div>
                <VideoRow
                    videoData={playlist[0]}
                    showCountdown={isEndscreenVisible}
                    duration={duration}
                    currentPosition={currentPosition}
                    onClick={() => playVideo(playlist[0])}
                    tabIndex={0}
                    {...commonVideoRowProps}
                />
                {showVideoTwo && (
                    <VideoRow videoData={playlist[1]} onClick={() => playVideo(playlist[1])} {...commonVideoRowProps} />
                )}
            </Modal>
        );
    }
}

function mapStateToProps(reduxState) {
    return {
        width: reduxState.width,
        currentPosition: reduxState.currentPosition,
        videoData: reduxState.videoData,
        duration: reduxState.duration,
        isEndscreenVisible: reduxState.isEndscreenVisible,
        currentBreakpoint: reduxState.currentBreakpoint,
        isControlsVisible: reduxState.isControlsVisible,
        playlist: reduxState.playlist
    };
}

const ConnectedEndscreen = connect(mapStateToProps, {
    showEndscreen,
    hideEndscreen,
    replacePlaylist
})(Endscreen);

export const UnconnectedEndscreen = Endscreen;
export default ConnectedEndscreen;
