import { h } from 'preact';
import { useRef, useEffect, useState } from 'preact/hooks';
import { useSelector } from 'react-redux';
import cn from 'classnames';
import { removeZeros, gTimeFormat } from '../../../utils';

import styles from './styles.css';

function getOffset(playerWidth, mouseXCoord, previewEl) {
    if (!previewEl) {
        return 0;
    }
    let left = mouseXCoord - previewEl.offsetWidth / 2 + 20; // move the preview over a little so it is centered with the mouse

    // make sure preview is clamped to left of the player
    left = left < 0 ? 0 : left;

    // make sure preview is clamped to right of the player
    const right = playerWidth - previewEl.offsetWidth;
    return left > right ? right : left;
}

function useAspectRatio() {
    const doctypeID = useSelector((reduxState) => parseInt(reduxState.videoData?.doctypeID, 10));
    if (doctypeID === 134) {
        // vertical videos
        return 9 / 16;
    } else if (doctypeID === 144) {
        // square videos
        return 1;
    }
    return 16 / 9;
}

const ScrubPreview = (props) => {
    const thumbstripURL = useSelector((reduxState) => reduxState.videoData?.thumbstripURL);
    const duration = useSelector((state) => state.duration);
    const width = useSelector((state) => state.width);
    const isAdMode = useSelector((state) => state.isAdMode);
    const ratio = useAspectRatio();
    const [isLoaded, setIsLoaded] = useState(false);
    const previewRef = useRef(null);
    const imageRef = useRef(null);
    // reset isLoading flag when the thumbstrip changes
    useEffect(() => {
        if (thumbstripURL) {
            imageRef.current.src = thumbstripURL;
            setIsLoaded(true);
        } else {
            setIsLoaded(false);
        }
    }, [thumbstripURL]);

    if (!thumbstripURL) {
        return null;
    }

    const { mouseXCoord, scrubBarWidth, show } = props;
    const offset = getOffset(width, mouseXCoord, previewRef.current);
    const perc = Math.min(mouseXCoord / scrubBarWidth, 1);
    const previewStyles = { left: `${offset}px` };
    const imageStyles = {};
    if (isLoaded) {
        const { naturalWidth, naturalHeight } = imageRef.current;
        const newHeight = naturalHeight / 2;
        const previewWidth = newHeight * ratio;
        imageStyles.left = `${Math.round(perc * 100) * -previewWidth}px`;
        imageStyles.width = `${naturalWidth / 2}px`;
        imageStyles.height = `${newHeight}px`;
        previewStyles.width = `${previewWidth}px`;
        previewStyles.height = `${newHeight}px`;
    }

    const hide = !isLoaded || !show || isAdMode;
    return (
        <div
            onselectstart="return false;"
            ref={previewRef}
            className={cn(styles.preview, { [styles.hide]: hide })}
            style={previewStyles}
            data-testid="video-scrub-preview"
        >
            <span className={styles.time}>{removeZeros(gTimeFormat(perc * duration))}</span>
            <img ref={imageRef} className={styles.img} style={imageStyles} alt="" />
        </div>
    );
};

export default ScrubPreview;
