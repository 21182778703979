import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { showCountdown, hideCountdown } from '../../actions';
import { fetchServerTime } from '../../utils';
import { getSources, getThumbWidth } from '../../thumb-utils';
import styles from './styles.css';

const padNumber = (num) => num.toString().padStart(2, '0');

const CountdownSquare = (props) => {
    return (
        <div className={styles.countdownSquare}>
            <div className={styles.squareNumber}>{props.value}</div>
            <div className={styles.squareTime}>{props.time}</div>
        </div>
    );
};

class LiveCountdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timerStarted: false,
            secondsRemaining: null,
            endCountdown: false,
            serverTime: null
        };
        this.interval = null;
    }

    componentDidMount() {
        if (this.props.videoData) {
            fetchServerTime(this.props.videoApiUrl + 'time')
                .then((rtn) => {
                    this.setState({ serverTime: rtn.time });
                })
                .catch((error) => {
                    console.error('Failed to fetch server time', error);
                    this.setState({ serverTime: Date.now() });
                });
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.state.serverTime !== null &&
            !this.state.timerStarted &&
            this.props.videoData &&
            this.props.videoData.formattedCreationDate &&
            this.props.videoData.state === 'future'
        ) {
            // adjust time if a more accurate time has been returned by _fetchServerTime

            const serverTime = new Date(this.state.serverTime);
            const startTime = new Date(this.props.videoData.iso8601CreationDate);
            const dif = startTime.getTime() - serverTime.getTime();
            // Not perfect. Doesn't take into account how long it took to fetch the time and event loop lag. Should be good enough though
            const timeOffset = Date.now() - serverTime.getTime();

            if (dif > 0) {
                const getSecondsRemaining = () => (startTime.getTime() - Date.now() + timeOffset) / 1000;
                this.setState({ serverTime: null, secondsRemaining: getSecondsRemaining(), timerStarted: true });
                this.interval = setInterval(() => this.setState({ secondsRemaining: getSecondsRemaining() }), 250);
                this.props.showCountdown();
            }
        }

        if (this.state.timerStarted && this.state.secondsRemaining < 1 && this.props.isCountdownVisible) {
            clearInterval(this.interval);
            this.props.hideCountdown();
            this.props.onCountdownComplete();
        }
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    _getThumbnail() {
        if (this.props.thumbnailOverride) {
            // external thumbnail provided so use that
            return this.props.thumbnailOverride;
        }

        const { videoData, currentBreakpoint, height } = this.props;
        const sources = getSources(videoData, getThumbWidth(currentBreakpoint), height);

        return sources?.default;
    }

    render() {
        if (!this.props.isCountdownVisible || this.props.videoData.state !== 'future') {
            return null;
        }

        let days, hours, minutes, seconds;
        if (this.state.secondsRemaining) {
            seconds = parseInt(this.state.secondsRemaining, 10);
            days = Math.floor(seconds / (3600 * 24));
            if (days > 0) seconds -= days * 3600 * 24;
            hours = Math.floor(seconds / 3600);
            seconds -= hours * 3600;
            minutes = Math.floor(seconds / 60);
            seconds -= minutes * 60;
        }

        return (
            <div className={styles.countdownContainer}>
                <div
                    style={{
                        backgroundImage: `linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.8) 100%), url(${this._getThumbnail()})`
                    }}
                    className={styles.thumbnail}
                ></div>
                <div className={styles.countdown}>
                    <div className={styles.title}>
                        {this.state.secondsRemaining
                            ? this.props.isQAEvent
                                ? `LIVE Q&A BEGINS IN`
                                : `LIVE STREAM BEGINS IN`
                            : ``}
                    </div>
                    {this.state.secondsRemaining !== null && days !== null && (
                        <div>
                            {days > 0 && <CountdownSquare value={padNumber(days)} time={days === 1 ? 'DAY' : 'DAYS'} />}
                            <CountdownSquare value={padNumber(hours)} time={hours === 1 ? 'HOUR' : 'HOURS'} />
                            <CountdownSquare value={padNumber(minutes)} time={minutes === 1 ? 'MINUTE' : 'MINUTES'} />
                            {days < 1 && (
                                <CountdownSquare
                                    value={padNumber(seconds)}
                                    time={seconds === 1 ? 'SECOND' : 'SECONDS'}
                                />
                            )}
                        </div>
                    )}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        videoData: state.videoData,
        currentBreakpoint: state.currentBreakpoint,
        height: state.height,
        isQAEvent: state.videoData?.isQAEvent,
        isCountdownVisible: state.isCountdownVisible
    };
}

const ConnectedLiveCountdown = connect(mapStateToProps, { showCountdown, hideCountdown })(LiveCountdown);

export default ConnectedLiveCountdown;

/*
const ConnectedLiveCountdown = connect(mapStateToProps, { showCountdown, hideCountdown })(LiveCountdown);

module.exports = {
  LiveCountdown: ConnectedLiveCountdown,
  UnconnectedLiveCountdown: LiveCountdown
};
*/
