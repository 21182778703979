import type { LegacyApiMediaItem } from '@video/common';
import { Stage, ApiFieldsArray } from '../types';

interface FindAllVideosParams {
    fb?: boolean;
    fields?: string[] | string;
    groupid?: string;
    query?: string;
    timeout?: number;
    type: string;
    userid?: string;
    signal?: AbortSignal;
    stage?: Stage;
    count?: string;
}

type GetVideoInfoParams = Pick<FindAllVideosParams, 'fields' | 'timeout' | 'stage'>;

const defaultFieldsString = ApiFieldsArray.join(',');

export function getApiUrlOverride(stage?: string) {
    switch (stage) {
        case 'prod':
            return 'https://video-api.shdsvc.dowjones.io';
        case 'nonprod':
        case 'dev':
            return 'https://video-api.dev.shdsvc.dowjones.io';
        default:
            return null;
    }
}

export async function findAllVideos(params: FindAllVideosParams): Promise<LegacyApiMediaItem[]> {
    const stage = params.stage ?? (globalThis as any)._videoApiEnv;
    const url = new URL(`${getApiUrlOverride(stage) ?? process.env.VIDEO_API_2}/api/legacy/find-all-videos`);
    const fields = Array.isArray(params.fields)
        ? params.fields.join(',')
        : typeof params.fields === 'string'
        ? params.fields
        : defaultFieldsString;

    url.searchParams.append('type', params.type);
    url.searchParams.append('fields', fields);
    url.searchParams.append('count', params.count || '1');

    if (params.query) {
        url.searchParams.append('query', params.query);
    }

    if (params.groupid) {
        url.searchParams.append('groupid', params.groupid);
    }

    if (params.userid) {
        url.searchParams.append('userid', params.userid);
    }

    if (params.fb) {
        url.searchParams.append('fb', '1');
    }

    const signals = [];
    if (params.timeout) {
        // TODO: Refactor to use https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/timeout_static when support is more widespread
        const ac = new AbortController();
        setTimeout(() => ac.abort(), params.timeout);
        signals.push(ac.signal);
    }

    if (params.signal) {
        signals.push(params.signal);
    }

    const fetchOptions: any = {};
    if (signals.length > 0) {
        // TODO: Refactor to use https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/any_static when support is more widespread
        const ac = new AbortController();
        fetchOptions.signal = ac.signal;
        signals.forEach((signal) => signal.addEventListener('abort', () => ac.abort()));
    }

    const res = await fetch(url.toString(), fetchOptions);
    const data = await res.json();
    return data?.items ?? [];
}

export async function getVideoInfo(guid: string, params?: GetVideoInfoParams): Promise<LegacyApiMediaItem> {
    const stage = params?.stage ?? (globalThis as any)._videoApiEnv;
    const url = new URL(`${getApiUrlOverride(stage) ?? process.env.VIDEO_API_2}/api/legacy/get-video-info`);
    const fields = Array.isArray(params?.fields)
        ? params!.fields.join(',')
        : typeof params?.fields === 'string'
        ? params.fields
        : defaultFieldsString;

    url.searchParams.append('guid', guid);
    url.searchParams.append('fields', fields);

    const fetchOptions: any = {};
    if (params?.timeout) {
        // TODO: Refactor to use https://developer.mozilla.org/en-US/docs/Web/API/AbortSignal/timeout_static when support is more widespread
        const ac = new AbortController();
        fetchOptions.signal = ac.signal;
        setTimeout(() => ac.abort(), params.timeout);
    }

    const res = await fetch(url.toString(), fetchOptions);
    if (!res.ok) {
        throw new Error(`${res.status} ${res.statusText}`);
    }

    return res.json();
}

export async function getPlayerConfig(playerId: string): Promise<any> {
    const url = `${process.env.CDN_URL}/api-video/player/v3/config/${playerId}.json`;
    const res = await fetch(url);
    if (!res.ok) {
        throw new Error(`${res.status} ${res.statusText}`);
    }
    const data = await res.json();
    return data?.items?.[0] ?? {};
}
