import { h } from 'preact';
import { connect } from 'react-redux';
import { gTimeFormat, removeZeros } from '../../utils';

function TimeDisplay({ videoId, currentPosition = 0, duration = 0, isLive, isVolumeControlVisible }) {
    if (isLive) {
        return (
            <div className="video-time f-l" id={`video-time-${videoId}`}>
                LIVE
            </div>
        );
    }
    const formattedPosition = removeZeros(gTimeFormat(currentPosition));
    const formattedDuration = removeZeros(gTimeFormat(duration));

    return (
        <div className="video-time f-l" id={`video-time-${videoId}`} data-testid="video-time">
            {formattedPosition}
            <span style={{ opacity: '.5' }}> / </span>
            {formattedDuration}
        </div>
    );
}

function mapStateToProps(reduxState) {
    return {
        currentPosition: reduxState.currentPosition,
        duration: reduxState.duration,
        isLive: reduxState.videoData.state === 'live' || reduxState.videoData.state === 'flive',
        isVolumeControlVisible: reduxState.isVolumeControlVisible
    };
}

const ConnectedTimeDisplay = connect(mapStateToProps)(TimeDisplay);

export default ConnectedTimeDisplay;
