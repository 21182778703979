import { h } from 'preact';
import cn from 'classnames';
import { useEffect } from 'preact/hooks';
import { memo } from 'preact/compat';
import { useSelector } from '../../hooks';
import styles from './styles.css';

interface SubscribeScreenProps {
    videoId: string;
    onSubscribeClicked: () => void;
    onSubscribeShown: () => void;
}

const SubscribeScreen = (props: SubscribeScreenProps) => {
    const visible = useSelector((state) => state.isSubscribeScreenVisible);

    const isCXSenseAvailable =
        typeof window !== 'undefined' &&
        window.cxdj?.displayVideoCandybar &&
        typeof window?.cxdj?.meta?.topDomain !== 'undefined';

    useEffect(() => {
        function callCXSense() {
            if (isCXSenseAvailable) window.cxdj.displayVideoCandybar(`candybar-${props.videoId}`);
        }
        if (visible) {
            callCXSense();
            props.onSubscribeShown();
        }
    }, [visible]);

    if (isCXSenseAvailable) {
        return (
            <div
                className={styles.subscribeScreenWrapper}
                style={{ display: visible ? 'block' : 'none' }}
                id={`candybar-${props.videoId}`}
                data-testid="video-subscribe-screen"
            ></div>
        );
    }

    return <SubscribeScreenFallback {...props} />;
};

const SubscribeScreenFallback = (props: SubscribeScreenProps) => {
    const width = useSelector((state) => state.width);
    const visible = useSelector((state) => state.isSubscribeScreenVisible);
    const videoData = useSelector((state) => state.videoData);

    if (!visible || !videoData?.guid) return null;

    const returnUrl = window.location != window.parent.location ? document.referrer : document.location.href;
    const domain = process.env.STAGE === 'nonprod' ? 'https://www.s.dev.wsj.com/' : 'https://www.wsj.com/';
    const loginModCode = `?mod=prem_login_${videoData.guid}`;
    const subModCode = `?mod=prem_subscribe_${videoData.guid}`;
    const anchor = `#${props.videoId}`;
    const notLoggedIn =
        !window?.utag_data?.user_type ||
        window?.utag_data?.user_type === 'nonsubscriber' ||
        window?.utag_data?.user_type === '';

    return (
        <div className={cn(styles.subscribeScreenWrapper, width < 550 ? styles.subscribeScreenWrapperSmall : '')}>
            <div className={styles.subscribeBar} data-testid="video-subscribe-screen">
                <div className={styles.subscribeMessage} data-testid="video-subscribe-screen-message">
                    <p>This video is available for WSJ subscribers only. Subscribe or sign in to watch.</p>
                </div>
                <div className={styles.subscribeButtonWrapper}>
                    <a
                        href={`https://subscribe.wsj.com/premiumSubscribe${subModCode + anchor}`}
                        role="button"
                        className={styles.buttonSubscribe}
                        onClick={() => {
                            props.onSubscribeClicked();
                        }}
                        data-testid="video-subscribe-screen-subscribe-btn"
                    >
                        Subscribe
                    </a>
                    {notLoggedIn && (
                        <a
                            href={`${domain}client/login?target=${encodeURIComponent(
                                returnUrl + loginModCode + anchor
                            )}`}
                            role="button"
                            className={styles.buttonSignIn}
                            data-testid="video-subscribe-screen-signin-btn"
                        >
                            Sign In
                        </a>
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(
    SubscribeScreen,
    (prevProps: any, nextProps: any) =>
        prevProps.visible === nextProps.visible || prevProps.videoData === nextProps.videoData
);
