const destroyDelay = 2; // seconds

class AdHealthObserver {
    constructor({ adsManager, onAdDisconnect, reduxStore }) {
        this._adsManager = adsManager;
        this._onAdDisconnect = onAdDisconnect;
        this._store = reduxStore;
        this._observing = false;
        this._isHealthy = true;

        this._clearAllTimeouts = this._clearAllTimeouts.bind(this);
        this._handleAdProgress = this._handleAdProgress.bind(this);
    }

    _isAdPlaying() {
        return this._store.getState().isAdMode;
    }

    _isAdBuffering() {
        return this._store.getState().isAdBuffering;
    }

    _clearAllTimeouts() {
        clearTimeout(this._adDelayTimeout);
        clearTimeout(this._adDestroyTimeout);
    }

    _handleAdProgress() {
        if (!this._isAdPlaying() || this._isAdBuffering()) {
            return;
        }

        this._adRemainder = this._adsManager.getRemainingTime();
        this._clearAllTimeouts();
        this._adDelayTimeout = setTimeout(() => {
            if (!this._isAdPlaying() || this._isAdBuffering()) {
                return;
            }

            const destroyTimeout = Math.max(this._adRemainder - destroyDelay, 0) * 1000;
            this._adDestroyTimeout = setTimeout(() => {
                this._isHealthy = false;
                this._onAdDisconnect();
            }, destroyTimeout);
        }, destroyDelay * 1000);
    }

    isHealthy() {
        return this._isHealthy;
    }

    observe() {
        if (this._observing) {
            return;
        }
        this._adsManager.addEventListener(google.ima.AdEvent.Type.PAUSED, this._clearAllTimeouts);
        this._adsManager.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, this._clearAllTimeouts);
        this._adsManager.addEventListener(google.ima.AdEvent.Type.AD_PROGRESS, this._handleAdProgress);
        this._observing = true;
    }

    disconnect() {
        if (!this._observing) {
            return;
        }
        this._clearAllTimeouts();
        this._adsManager.removeEventListener(google.ima.AdEvent.Type.PAUSED, this._clearAllTimeouts);
        this._adsManager.removeEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, this._clearAllTimeouts);
        this._adsManager.removeEventListener(google.ima.AdEvent.Type.AD_PROGRESS, this._handleAdProgress);
        this._observing = false;
    }
}

export default AdHealthObserver;
