import { VideoData } from '../../types';
import { Store } from '../store';
import { getChannelFromDomain, getCookie } from '../utils';

export function getSectionSubsection(data: VideoData) {
    const rtn = [];
    let prefix: 'wsj' | 'allthingsd' | 'sm' = 'wsj';
    let channel = getChannel();

    if (channel == 'AllThingsD') {
        prefix = 'allthingsd';
    } else if (channel == 'SmartMoney') {
        prefix = 'sm';
    }

    if (typeof (data as any)[prefix + '-section'] === 'string') {
        rtn.push((data as any)[prefix + '-section']);
    }
    if (typeof (data as any)[prefix + '-subsection'] === 'string') {
        rtn.push((data as any)[prefix + '-subsection']);
    }
    return rtn;
}

export function getTrackingDateString(data: VideoData) {
    try {
        var cd = new Date(data.formattedCreationDate ?? '');
        var publishedDate = data.omniPublishDate?.split('video_')[1] ?? '';
        var pd = new Date(publishedDate);

        return (
            'original_' +
            cd.getFullYear() +
            '-' +
            (cd.getMonth() + 1 < 10 ? '0' + (cd.getMonth() + 1) : cd.getMonth() + 1) +
            '-' +
            cd.getDate() +
            ' ' +
            cd.getHours() +
            ':' +
            (cd.getMinutes() < 10 ? '0' : '') +
            cd.getMinutes() +
            '_current_' +
            pd.getFullYear() +
            '-' +
            (pd.getMonth() + 1 < 10 ? '0' + (pd.getMonth() + 1) : pd.getMonth() + 1) +
            '-' +
            pd.getDate() +
            ' ' +
            pd.getHours() +
            ':' +
            (pd.getMinutes() < 10 ? '0' : '') +
            pd.getMinutes()
        );
    } catch (e) {
        return data.formattedCreationDate;
    }
}

export function getChannel(channelOverride?: string) {
    let channel = '';
    try {
        if (channelOverride) {
            channel = channelOverride;
        } else if (window['s']) {
            channel = window.s.channel;
        } else {
            channel = getChannelFromDomain(location.hostname);
        }
    } catch (e) {
        channel = getChannelFromDomain(location.hostname);
    }

    return channel;
}

export function getUserTypeVar() {
    let userTypeRaw = null;
    if (window.utag_data && window.utag_data.user_type) {
        userTypeRaw = window.utag_data.user_type;
    } else {
        const el = document.querySelector('meta[name="user.type"]');
        if (el !== null) {
            userTypeRaw = el.getAttribute('content');
        }
    }

    const userTypeMap: Record<string, string> = {
        nonsubscriber: 'free',
        subscriber: 'sub_yes',
        freeregister: 'mem_yes',
        procbsubscriber: 'sub_yes',
        prosubscriber: 'sub_yes',
        reguser: 'mem_yes',
        member: 'mem_yes'
    };

    let userType;
    if (userTypeRaw) {
        userType = userTypeMap[userTypeRaw.toLowerCase()];
        if (typeof userType === 'undefined') {
            // unknown user type so fall back to free
            userType = 'free';
        } else if (userType === 'free' && getCookie('TR')) {
            // TR cookie means that this user is "some-kind" of subscriber, but not logged In
            userType = 'reg_yes';
        }
    } else {
        // no user.type meta tag or utag_data value so fall back to free
        userType = 'free';
    }

    // We will make WSJ the default. Most apps have utag_data on the page so this shouldn't be an issue
    const product = window.utag_data?.page_site_product ?? 'WSJ';

    return `${product}_${userType}`;
}

export function getVideoContent() {
    try {
        if (window != window.top) {
            if (window.parent.s !== undefined) {
                return window.parent.s;
            }
        } else {
            if (window.s !== undefined) {
                return window.s;
            }
        }
        return {};
    } catch (e) {
        return {};
    }
}

export function getContentTypes() {
    var rtn = ['video embedded offsite', 'video embedded offsite'];

    if (getChannel()) {
        var pageValues = getVideoContent();
        rtn[0] = pageValues.prop1 !== undefined ? pageValues.prop1 : 'Embedded Onsite';
        rtn[1] =
            pageValues.prop19 !== undefined
                ? pageValues.prop19
                : pageValues.prop1 !== undefined
                ? pageValues.prop1
                : 'Embedded Onsite';
    }
    return rtn;
}

export function getStreamFormat(store: Store) {
    let streamFormat = 'user initiated';

    const { formatOverride, hasAutoplayed, hasChained, isMuted, videoData } = store.getState();

    if (formatOverride) {
        streamFormat = formatOverride;
    } else if (videoData.startAction) {
        streamFormat = isMuted ? videoData.startAction + ' muted' : videoData.startAction;
    } else if (hasChained) {
        streamFormat = isMuted ? 'autochain muted' : 'autochain';
    } else if (!hasAutoplayed) {
        streamFormat = 'user initiated'; // we dont want to track autoplay if autoplay is not allowed by the browser
    } else {
        streamFormat = isMuted ? 'autoplay muted' : 'autoplay';
    }

    return streamFormat;
}
