import { h, FunctionalComponent, JSX } from 'preact';
import cn from 'classnames';
import { useSelector } from '../../hooks';
import CloseMedium from 'wsj-svg/lib/CloseMedium';
import PauseCircularStrokeMedium from 'wsj-svg/lib/PauseCircularStrokeMedium';
import PlayCircularStrokeMedium from 'wsj-svg/lib/PlayCircularStrokeMedium';
import { gTimeFormat } from '../../utils';
import styles from './styles.css';

interface StickyPlayerControlsProps {
    className?: string;
    Container?: JSX.ElementType;
    onClose?: () => void;
    onPlayPause?: () => void;
}

const StickyPlayerControls: FunctionalComponent<StickyPlayerControlsProps> = ({
    className,
    Container = 'div',
    onClose,
    onPlayPause
}) => {
    const videoData = useSelector((state) => state.videoData);
    const duration = useSelector((state) => state.duration);
    const currentPosition = useSelector((state) => state.currentPosition);
    const isPlaying = useSelector((state) => state.isPlaying);
    const isAdMode = useSelector((state) => state.isAdMode);
    const isAdPlaying = useSelector((state) => state.isAdPlaying);
    const adDuration = useSelector((state) => state.currentAdDuration);
    const adPosition = useSelector((state) => state.currentAdPosition);

    return (
        <Container className={className}>
            <h4 className={styles.title}>{videoData.name}</h4>
            <div className={styles.controls}>
                <button
                    className={cn(styles.btn, styles.playPause)}
                    aria-label={isPlaying ? 'Pause' : 'Play'}
                    onClick={onPlayPause}
                >
                    {(!isAdMode && isPlaying) || (isAdMode && isAdPlaying) ? (
                        <PauseCircularStrokeMedium />
                    ) : (
                        <PlayCircularStrokeMedium />
                    )}
                </button>
                <p className={styles.time}>
                    {gTimeFormat(isAdMode ? adPosition : currentPosition)} /{' '}
                    {gTimeFormat(isAdMode ? adDuration : duration)}
                </p>
                <button className={cn(styles.btn, styles.closeBtn)} onClick={onClose}>
                    <CloseMedium />
                </button>
            </div>
        </Container>
    );
};

export default StickyPlayerControls;
