import { h, render, Fragment, hydrate } from 'preact';
import { useCallback } from 'preact/hooks';
import { Provider, useDispatch } from 'react-redux';
import cn from 'classnames';
import diff from 'semver/functions/diff';
import { emptyElement, hydrateWithWarnings, createMark } from './utils';
import { useSelector } from './hooks';
import Controls from './components/Controls';
import MoreVideosSlide from './components/MoreVideosSlide';
import Endscreen from './components/Endscreen';
import Thumbnail from './components/Thumbnail';
import SubscribeScreen from './components/SubscribeScreen';
import Title from './components/Title';
import SavedQueueModal from './components/SavedQueueModal';
import LiveCountdown from './components/LiveCountdown';
import ClickForSound from './components/ClickForSound';
import LiveIndicator from './components/LiveIndicator';
import LoadingSpinner from './components/LoadingSpinner';
import SkipAdButton from './components/SkipAdButton/index.js';
import AdCountdownCircle from './components/AdCountdownCircle/index.js';
import { UIProps } from '../types';
import StickyPlayerControls from './components/StickyPlayerControls';
import { setFloating, setFloatingBlocked } from './actions';
import { setCustomAttribute } from './perf-utils';

const videoStyles = { width: '100%', height: '100%', position: 'absolute' };

export const UI = (props: UIProps) => {
    const breakpoint = useSelector((state) => state.currentBreakpoint);
    const isThumbnailVisible = useSelector((state) => state.isThumbnailVisible);
    const isStickyModeEnabled = useSelector((state) => state.isStickyModeEnabled);
    const isFloating = useSelector((state) => state.isFloating);
    const aspectRatio = useSelector((state) => state.videoData.aspectRatio ?? 16 / 9);
    const isPremium = useSelector((state) => state.videoData.isSubscriberOnly);
    const dispatch = useDispatch();

    const handleStickyClose = useCallback(() => {
        dispatch(setFloating(false));
        dispatch(setFloatingBlocked(true));
    }, []);

    const {
        videoId,
        controlsProps,
        thumbProps,
        countdownProps,
        moreVideosSlideProps,
        endscreenProps,
        clickForSoundProps,
        disableTitle,
        liveButtonEnabled,
        saveToQueueEnabled,
        subscribeScreenProps,
        adProps,
        enableStickyPlayer,
        stickyPlayerTheme = 'default',
        videoProps
    } = props;

    const isDocked = enableStickyPlayer && isFloating;
    const isDockedWSJArticle = isDocked && stickyPlayerTheme === 'wsj-article';
    const isVertical = aspectRatio < 1;

    return (
        <div
            className={cn(
                'video-wrapper',
                `player-${breakpoint}`,
                {
                    [`docked-${stickyPlayerTheme}`]: isDocked,
                    vertical: isVertical
                },
                { paywall: isPremium }
            )}
            id={`wrapper-${videoId}`}
            data-server-state={props.serverState ?? ''}
            data-version={process.env.VERSION}
            data-testid={`video-player${isFloating ? '-docked' : ''}`}
        >
            <div className="video-inner">
                <SubscribeScreen {...subscribeScreenProps} videoId={videoId} />
                <LiveCountdown {...countdownProps} />
                {!disableTitle && !isStickyModeEnabled && !isDockedWSJArticle && <Title videoId={videoId} />}
                <SkipAdButton videoId={videoId} {...adProps} />
                {moreVideosSlideProps && !isDockedWSJArticle && (
                    <MoreVideosSlide videoId={videoId} {...moreVideosSlideProps} />
                )}
                {endscreenProps && !isStickyModeEnabled && !isDockedWSJArticle && <Endscreen {...endscreenProps} />}
                <div
                    class="video-share"
                    id={`video-share-${videoId}`}
                    tabIndex={-1}
                    role="menu"
                    aria-expanded="false"
                    aria-labelledby={`video-share-b-${videoId}`}
                />
                <video
                    src=""
                    id={`videoplayer-${videoId}`}
                    style={videoStyles}
                    preload="none"
                    crossOrigin="anonymous"
                    controls
                    {...videoProps}
                >
                    Your browser does not support HTML5 video.
                </video>
                <div class="video-play-overlay" id={`video-play-overlay-${videoId}`}></div>
                <LoadingSpinner />
                <div class="video-adContainer" id={`adContainer-${videoId}`}>
                    <div class="ad-top-background"></div>
                    {!isStickyModeEnabled && !isDockedWSJArticle && <AdCountdownCircle videoId={videoId} />}
                </div>
                <div
                    id={`video-controls-container-${videoId}`}
                    class="video-controls-container"
                    style={
                        isThumbnailVisible || isStickyModeEnabled || isDockedWSJArticle
                            ? { display: 'none' }
                            : undefined
                    }
                >
                    {controlsProps && <Controls videoId={videoId} {...controlsProps} />}
                </div>
                {saveToQueueEnabled && <SavedQueueModal />}
                {clickForSoundProps && <ClickForSound videoId={videoId} {...clickForSoundProps} />}
                {liveButtonEnabled && !disableTitle && <LiveIndicator videoId={videoId} />}
                <Thumbnail videoId={videoId} {...thumbProps} />
            </div>
            {isDockedWSJArticle && (
                <StickyPlayerControls
                    className="video-wsj-article-docked-controls"
                    onClose={handleStickyClose}
                    onPlayPause={controlsProps?.onPlayPause}
                />
            )}
        </div>
    );
};

export function renderUI(el: Element, store: any, settings: UIProps) {
    const app = (
        <Provider store={store}>
            <UI {...settings} />
        </Provider>
    );

    // Prevent hydration if the major or minor version is different between client and server
    const serverVersion = el.querySelector(`#wrapper-${settings.videoId}`)?.getAttribute('data-version') ?? '';
    let isHydrateCompatible = false;
    try {
        const versionDiff = diff(serverVersion, process.env.VERSION ?? '');
        isHydrateCompatible =
            versionDiff !== 'major' &&
            versionDiff !== 'minor' &&
            versionDiff !== 'premajor' &&
            versionDiff !== 'preminor';
    } catch (e) {
        console.warn('Failed to check version compatibility', e);
    }

    createMark('videoRender:start');
    if (settings.useHydrate && isHydrateCompatible) {
        setCustomAttribute('video-player-hydrate', true);
        process.env.STAGE === 'nonprod' ? hydrateWithWarnings(app, el) : hydrate(app, el);
    } else {
        if (!isHydrateCompatible) {
            console.warn(
                `Server side render version mismatch. Server: ${serverVersion} Client: ${process.env.VERSION}`
            );
        }
        emptyElement(el); // Clear out the element we are rendering into. MW Video center adds an <img> that we need to remove
        render(app, el);
    }
    createMark('videoRender:end');
}
