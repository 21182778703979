const baseUrl = `https://seshat.${process.env.STAGE === 'prod' ? '' : 'dev.'}shdsvc.dowjones.io`;

export async function trackView(guid: string, sourceId: string): Promise<any> {
    try {
        const res = await fetch(`${baseUrl}/api/track?guid=${guid}&source=${sourceId}`);
        if (!res.ok) {
            throw new Error(`${res.status} ${res.statusText}`);
        }
    } catch (err) {
        console.error('Seshat Error', err);
    }
}
