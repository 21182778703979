import { h, Component, Fragment, createRef } from 'preact';
import { connect } from 'react-redux';
import cn from 'classnames';
import * as loadState from '../../../loadState';
import { isExperimentRunning, sendOptimizelyEvent } from '../../../utils';

import playIcon from '../../../../svg/Icons_Play_Play.svg?inline';
import pauseIcon from '../../../../svg/Icons_Play_Pause.svg?inline';

function noop() {}

class PlayPauseButton extends Component {
    constructor(props) {
        super(props);
        this._pickIcon = this._pickIcon.bind(this);
        this._playButton = createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            this.props.focusElement == 'play-button' &&
            this.props.focusElement !== prevProps.focusElement &&
            this._playButton
        ) {
            this._playButton.current.focus({ preventScroll: true });
        }
    }

    _pickIcon() {
        const { isPlaying, isAdPlaying, isAdMode, isPlaybackInitializing } = this.props;

        if (isPlaybackInitializing) {
            return playIcon;
        }
        if (isAdMode) {
            return isAdPlaying ? pauseIcon : playIcon;
        }

        return isPlaying ? pauseIcon : playIcon;
    }

    shouldComponentUpdate(nextProps) {
        // only re render when these props change
        return (
            this.props.isPlaying !== nextProps.isPlaying ||
            this.props.isAdPlaying !== nextProps.isAdPlaying ||
            this.props.isAdMode !== nextProps.isAdMode ||
            this.props.isPlaybackInitializing !== nextProps.isPlaybackInitializing ||
            this.props.focusElement !== nextProps.focusElement ||
            this.props.isBtnDisabled !== nextProps.isBtnDisabled
        );
    }

    onClickEvent(e) {
        const { isBtnDisabled, isPlaying: isContentPlaying, isAdMode, isAdPlaying } = this.props;
        if (!isBtnDisabled) {
            const isPlaying = isAdMode ? isAdPlaying : isContentPlaying;
            if (isExperimentRunning('102p') && isPlaying) {
                sendOptimizelyEvent('102p_total_clicks_to_pause_homepage_video_players');
            }
            if (isExperimentRunning('103p') && isPlaying) {
                sendOptimizelyEvent('103p_total_clicks_to_pause_homepage_video_players');
            }
            this.props.onPlayPause(e);
        }
    }

    render() {
        const { isBtnDisabled, isPlaying: isContentPlaying, isAdMode, isAdPlaying } = this.props;
        const isPlaying = isAdMode ? isAdPlaying : isContentPlaying;
        return (
            <Fragment>
                <button
                    ref={this._playButton}
                    type="button"
                    aria-label={isPlaying ? 'Pause' : 'Play'}
                    onClick={(e) => {
                        this.onClickEvent(e);
                    }}
                    className={cn('video-play-pause', 'f-l', { 'disabled-btn': isBtnDisabled })}
                    dangerouslySetInnerHTML={{ __html: this._pickIcon() }}
                    data-testid={`video-${isPlaying ? 'pause' : 'play'}-button`}
                />
                <div class="playback-status visually-hidden" aria-live="polite">
                    {isPlaying ? 'Playing' : 'Paused'}
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state) {
    return {
        isPlaying: state.isPlaying,
        isAdPlaying: state.isAdPlaying,
        isAdMode: state.isAdMode,
        isPlaybackInitializing: state.isPlaybackInitializing,
        focusElement: state.focusElement,
        isBtnDisabled:
            state.isPlaybackInitializing ||
            (state.adInitState === loadState.STARTED && !state.isPlaying) ||
            state.videoApiLoadState === loadState.STARTED ||
            state.hlsJsLoadState === loadState.STARTED
    };
}

const ConnectedPlayPauseButton = connect(mapStateToProps)(PlayPauseButton);

export default ConnectedPlayPauseButton;
