import type { LegacyApiMediaItem } from '@video/common';

export type Breakpoint = '16U' | '12U' | '8U' | '4U' | 'tiny';
export type Stage = 'prod' | 'nonprod';
export type ThumbLayout = string; // TODO: List out the specific strings

export const ApiFieldsArray = [
    'adCategory',
    'adTagParams',
    'adZone',
    'adsAllowed',
    'aspectRatio',
    'author',
    'captionsVTT',
    'catastrophic',
    'chapterTimes',
    'column',
    'description',
    'doctypeID',
    'duration',
    'editor',
    'emailURL',
    'episodeNumber',
    'forceClosedCaptions',
    'format',
    'formattedCreationDate',
    'gptCustParams',
    'guid',
    'hls',
    'hlsNoCaptions',
    'isQAEvent',
    'iso8601CreationDate',
    'isSubscriberOnly',
    'keywords',
    'keywordsOmni',
    'linkRelativeURL',
    'linkShortURL',
    'linkURL',
    'mediaLiveChannelId',
    'name',
    'omniProgramName',
    'omniPublishDate',
    'omniVideoFormat',
    'provider',
    'rssURL',
    'secondsUntilStartTime',
    'seriesName',
    'showName',
    'sponsoredVideo',
    'lang',
    'state',
    'suppressAutoplay',
    'thumbnailImageManager',
    'thumbnailList',
    'thumbstripURL',
    'thumbstripVTTURL',
    'titletag',
    'touchCastID',
    'type',
    'video1264kMP4Url',
    'video174kMP4Url',
    'video1864kMP4Url',
    'video2564kMP4Url',
    'video320kMP4Url',
    'video664kMP4Url',
    'videoBestQualityWebmUrl',
    'videoMP4List',
    'videoStillURL',
    'wsj-section',
    'wsj-subsection',
    'wsj-packages',
    'factiva-subjects',
    'factiva-regions'
] as const;

export type ApiFields = (typeof ApiFieldsArray)[number];

export type VideoData = Pick<LegacyApiMediaItem, ApiFields> & { startAction?: string };

export interface UIProps extends Pick<Settings, 'enableStickyPlayer' | 'disableTitle' | 'stickyPlayerTheme'> {
    adProps: {
        onSkip?: () => void;
    };
    clickForSoundProps: {
        onClicked?: () => void;
        isCountdownVisible?: boolean;
        isLiveButtonVisible?: boolean;
    };
    controlsProps?: {
        enableScrubPreview: boolean;
        isMobile: boolean;
        onPlayPause?: (e?: any) => void;
        onMuteUnMute?: () => void;
        onSetVolume?: (vol: number) => void;
        onVolumeControlVisible?: (isVisible: boolean) => void;
        onToggleFullScreen?: () => void;
        onSeek?: (perc: number) => void;
        saveApi?: string;
        saveApiPublication?: string;
        showControls?: () => void;
        showShareScreen?: () => void;
        saveToQueueEnabled: boolean;
        onSaveChange?: (isSaved: boolean) => void;
    } | null;
    countdownProps: {
        videoApiUrl?: string;
        onCountdownComplete?: () => void;
    };
    disableTitle?: boolean;
    endscreenProps?: {
        videoApiUrl: string;
        fields: string;
        playerSettings: Record<string, unknown>;
        playVideo?: (e: any) => void;
        onEndsceenShown?: () => void;
    };
    liveButtonEnabled: boolean;
    moreVideosSlideProps?: {
        onSlideShowing?: () => void;
        playVideo?: (guid: string) => void;
    };
    saveToQueueEnabled: boolean;
    serverState?: string;
    thumbProps: {
        layout: ThumbLayout;
        showFlashLine?: boolean;
        suppressHeadline?: boolean;
        thumbnailOverride?: string;
        thumbPreviewEnabled?: boolean;
        onClick?: (progress?: number) => void;
    };
    subscribeScreenProps: {
        onSubscribeClicked: () => void;
        onSubscribeShown: () => void;
    };
    useHydrate?: boolean;
    videoId: string;
    videoProps: {
        disablepictureinpicture?: boolean;
    };
}

export interface Settings {
    adSkipTime?: number;
    adTag?: string;
    adZone?: string;
    adsEnabled?: boolean;
    allowFullScreen?: boolean;
    allowPlayerPopup?: boolean;
    api?: string;
    api2?: string;
    autoplay?: boolean | 'muted';
    callback?: () => void;
    chainVideos?: boolean;
    channel?: string;
    clickForSound?: boolean;
    collapseable?: boolean;
    contentType?: string;
    count?: number;
    directLinks?: boolean;
    disableChainPlay?: boolean;
    disableDVR?: boolean;
    disableHtmlControls?: boolean;
    disableTitle?: boolean;
    enableAutoplayMutedBehavior?: boolean;
    enableEndScreen?: boolean;
    enableLiveButton?: boolean;
    enableMoreVideosSlide?: boolean;
    enableScrubPreview?: boolean;
    enableStickyPlayer?: boolean;
    enableThumbPreview?: boolean;
    fallback?: boolean;
    fireTv?: boolean;
    groupId?: string;
    guid: string;
    hasCMPPermutiveConsent?: boolean;
    imaIasNetworkId?: string;
    imaLibrary?: string;
    larsAdId?: string;
    larsId?: string;
    lnid?: string;
    loaderThumb?: boolean;
    loop?: boolean;
    maxBitrateIndex?: number | null;
    moduleId?: string;
    msrc?: string | null;
    noThumb?: boolean;
    oAccount?: string;
    parentUrl?: string;
    playInline?: boolean;
    playlist?: Record<string, unknown>;
    playlistQuery?: string;
    playerid?: string;
    /**
     * Overrides the plcmt value in the ad tag. Defaults to 2
     * @see {@link https://support.google.com/admanager/answer/10661571?sjid=11900967556916741456-NA#plcmt Google Ad Manager PLCMT documentation}
     * @see {@link https://github.com/InteractiveAdvertisingBureau/AdCOM/blob/develop/AdCOM%20v1.0%20FINAL.md#list_plcmtsubtypesvideo IAB AdCOM Placement Subtypes}
     */
    plcmtOverride?: number;
    plid?: string;
    prerollDelay?: number;
    query?: string;
    relativeLinks?: boolean;
    resetOnComplete?: boolean;
    sAccount?: string;
    saveApi?: string;
    saveApiPublication?: string;
    shareDomain?: string;
    shareEnabled?: boolean;
    site?: string;
    startPosition?: number;
    startLevel?: boolean | number;
    stickyPlayerTheme?: 'default' | 'wsj-article';
    suggestionsGroupId?: string;
    suggestionsQuery?: string;
    suggestionsType?: string;
    suppressHeadline?: boolean;
    thumb?: string;
    thumbFlashLine?: boolean;
    thumbLayout?: ThumbLayout;
    touchCastID?: string | null;
    trackInView?: boolean;
    type?: string;
    useAllesseh?: boolean;
    useHLS?: boolean;
    useHttps?: boolean;
    useWebm?: boolean;
    videoApiEnv?: Stage | 'dev';
    vpaidEnabled?: boolean;
    disablePictureInPicture?: boolean;
}

//#region Google IMA SDK Types
export type Ad = {
    getAdId: () => string;
    getAdPodInfo: () => AdPodInfo;
    getAdSystem: () => string;
    getAdvertiserName: () => string;
    getApiFramework: () => string | null;
    getCompanionAds: (
        adSlotWidth: number,
        adSlotHeight: number,
        settings?: CompanionAdSelectionSettings
    ) => CompanionAd[];
    getContentType: () => string;
    getCreativeAdId: () => string;
    getCreativeId: () => string;
    getDealId: () => string;
    getDescription: () => string;
    getDuration: () => number;
    getHeight: () => number;
    getMediaUrl: () => string | null;
    getMinSuggestedDuration: () => number;
    getSkipTimeOffset: () => number;
    getSurveyUrl: () => string | null;
    getTitle: () => string;
    getTraffickingParameters: () => { [key: string]: string };
    getTraffickingParametersString: () => string;
    getUiElements: () => string[];
    getUniversalAdIdRegistry: () => string;
    getUniversalAdIdValue: () => string;
    getUniversalAdIds: () => UniversalAdIdInfo[];
    getVastMediaBitrate: () => number;
    getVastMediaHeight: () => number;
    getVastMediaWidth: () => number;
    getWidth: () => number;
    getWrapperAdIds: () => string[];
    getWrapperAdSystems: () => string[];
    getWrapperCreativeIds: () => string[];
    isLinear: () => boolean;
};

type AdPodInfo = {
    getAdPosition: () => number;
    getIsBumper: () => boolean;
    getMaxDuration: () => number;
    getPodIndex: () => number;
    getTimeOffset: () => number;
    getTotalAds: () => number;
};

type CompanionAdSelectionSettings = {
    adSlotIds: string[];
    creativeType: CreativeType;
    nearMatchPercent: number;
    resourceType: ResourceType;
    sizeCriteria: SizeCriteria;
};

enum CreativeType {
    IMAGE = 'IMAGE',
    FLASH = 'FLASH',
    ALL = 'ALL'
}

enum ResourceType {
    HTML = 'HTML',
    IFRAME = 'IFRAME',
    STATIC = 'STATIC',
    ALL = 'ALL'
}

enum SizeCriteria {
    IGNORE = 'IGNORE',
    SELECT_EXACT_MATCH = 'SELECT_EXACT_MATCH',
    SELECT_NEAR_MATCH = 'SELECT_NEAR_MATCH',
    SELECT_FLUID = 'SELECT_FLUID'
}

enum ViewMode {
    NORMAL = 'NORMAL',
    FULLSCREEN = 'FULLSCREEN'
}

type CompanionAd = {
    getAdSlotId: () => string | null;
    getContent: () => string;
    getContentType: () => string | null;
    getHeight: () => number;
    getWidth: () => number;
};

type UniversalAdIdInfo = {
    getAdIdRegistry: () => string;
    getAdIdValue: () => string;
};

/**
 * Defines parameters that control the rendering of ads.
 * https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdsRenderingSettings
 */
type AdsRenderingSettings = {
    /**
     * Set to false if you want to have fine grained control over the positioning of all non-linear ads.
     * If this value is true, the ad is positioned in the bottom center.
     * If this value is false, the ad is positioned in the top left corner.
     * The default value is true.
     */
    autoAlign: boolean;

    /**
     * Maximum recommended bitrate. The value is in kbit/s.
     * The SDK will pick media with bitrate below the specified max, or the closest bitrate if there is no media with lower bitrate found.
     * Default value, -1, means the SDK selects the maximum bitrate.
     */
    bitrate: number;

    /**
     * Enables preloading of video assets. For more info see our guide to preloading media.
     */
    enablePreloading: boolean;

    /**
     * Timeout (in milliseconds) when loading a video ad media file.
     * If loading takes longer than this timeout, the ad playback is canceled and the next ad in the pod plays, if available.
     * Use -1 for the default of 8 seconds.
     */
    loadVideoTimeout: number;

    /**
     * Only supported for linear video mime types.
     * If specified, the SDK will include media that matches the MIME type(s) specified in the list and exclude media that does not match the specified MIME type(s).
     * The format is a list of strings, for example, [ 'video/mp4', 'video/webm', ... ]
     * If not specified, the SDK will pick the media based on player capabilities.
     */
    mimeTypes: string[] | null;

    /**
     * For VMAP and ad rules playlists, only play ad breaks scheduled after this time (in seconds).
     * This setting is strictly after - for example, setting playAdsAfterTime to 15 will cause IMA to ignore an ad break scheduled to play at 15s.
     */
    playAdsAfterTime: number;

    /**
     * Specifies whether or not the SDK should restore the custom playback state after an ad break completes.
     * This is setting is used primarily when the publisher passes in its content player to use for custom ad playback.
     */
    restoreCustomPlaybackStateOnAdBreakComplete: boolean;

    /**
     * Specifies whether the UI elements that should be displayed.
     * The elements in this array are ignored for AdSense/AdX ads.
     */
    uiElements: string[] | null;

    /**
     * Render linear ads with full UI styling.
     * This setting does not apply to AdSense/AdX ads or ads played in a mobile context that already use full UI styling by default.
     */
    useStyledLinearAds: boolean;

    /**
     * Render non-linear ads with a close and recall button.
     */
    useStyledNonLinearAds: boolean;

    /**
     * Creates a new AdsRenderingSettings instance.
     */
    new (): AdsRenderingSettings;
};

/**
 * Provides the outer public API to the publisher and communicates with the inner instance of ads manager.
 * https://developers.google.com/interactive-media-ads/docs/sdks/html5/client-side/reference/js/google.ima.AdsManager
 */
export type AdsManager = {
    /**
     * Collapse the current ad. This is no-op for HTML5 SDK.
     */
    collapse(): void;

    /**
     * Sets required parameters for getting a reference to this AdManager from the AdsManagerLoaded event.
     * @param content Object that plays back publisher's content.
     * @param adsRenderingSettings Optional settings to control the rendering of ads.
     */
    configureAdsManager(content: Object, adsRenderingSettings?: AdsRenderingSettings | null): void;

    /**
     * Removes ad assets loaded at runtime that need to be properly removed at the time of ad completion and stops the ad and all tracking.
     */
    destroy(): void;

    /**
     * If an ad break is currently playing, discard it and resume content. Otherwise, ignore the next scheduled ad break.
     * For example, this can be called immediately after the ads manager loads to ignore a preroll without losing future midrolls or postrolls.
     * This is a no-op unless the ad request returned a playlist or VMAP response.
     */
    discardAdBreak(): void;

    /**
     * Expand the current ad. This is no-op for HTML5 SDK.
     */
    expand(): void;

    /**
     * Puts the focus on the skip button, if present. If not present, focus is put on interactive elements, including icons or interactive creatives.
     */
    focus(): void;

    /**
     * Returns true if the ad can currently be skipped. When this value changes, the AdsManager fires an AdEvent.SKIPPABLE_STATE_CHANGED event.
     * @returns True if the ad can currently be skipped, false otherwise.
     */
    getAdSkippableState(): boolean;

    /**
     * Returns an array of offsets in seconds indicating when a scheduled ad break will play.
     * A preroll is represented by 0, and a postroll is represented by -1.
     * An empty array indicates the ad or ad pod has no schedule and can be played at any time.
     * @returns List of time offsets in seconds.
     */
    getCuePoints(): number[];

    /**
     * Get the remaining time of the current ad that is playing.
     * If the ad is not loaded yet or has finished playing, the API would return -1.
     * @returns The time remaining for current ad. If the remaining time is undefined for the current ad (for example custom ads), the value returns -1.
     */
    getRemainingTime(): number;

    /**
     * Get the volume for the current ad.
     * @returns The volume of the current ad, from 0 (muted) to 1 (loudest).
     */
    getVolume(): number;

    /**
     * Call init to initialize the ad experience on the ads manager.
     * @param width The chosen width of the ad.
     * @param height The chosen height of the ad.
     * @param viewMode The chosen view mode.
     * @param videoElement The video element for custom playback. This video element overrides the one provided in the AdDisplayContainer constructor.
     */
    init(width: number, height: number, viewMode: ViewMode, videoElement?: HTMLVideoElement | null): void;

    /**
     * Returns true if a custom click tracking element is being used for click tracking on the current ad.
     * Custom click tracking is only used when an optional click tracking element is provided to the AdDisplayContainer,
     * custom playback is used, and the current ad is not an AdSense/AdX ad.
     * @returns Whether custom click tracking is used.
     */
    isCustomClickTrackingUsed(): boolean;

    /**
     * Returns true if a custom video element is being used to play the current ad.
     * Custom playback occurs when an optional video element is provided to the AdDisplayContainer
     * on platforms where a custom video element would provide a more seamless ad viewing experience.
     * @returns Whether custom playback is used.
     */
    isCustomPlaybackUsed(): boolean;

    /**
     * Pauses the current ad that is playing.
     * This function will be no-op when a static overlay is being shown or if the ad is not loaded yet or is done playing.
     */
    pause(): void;

    /**
     * Resizes the current ad.
     * @param width New ad slot width.
     * @param height New ad slot height.
     * @param viewMode The new view mode.
     */
    resize(width: number, height: number, viewMode: ViewMode): void;

    /**
     * Resumes the current ad that is loaded and paused.
     * This function will be no-op when a static overlay is being shown or if the ad is not loaded yet or is done playing.
     */
    resume(): void;

    /**
     * Set the volume for the current ad.
     * @param volume The volume to set, from 0 (muted) to 1 (loudest).
     */
    setVolume(volume: number): void;

    /**
     * Skips the current ad when AdsManager.getAdSkippableState() is true.
     * When called under other circumstances, skip has no effect.
     * After the skip is completed the AdsManager fires an AdEvent.SKIPPED event.
     * AdsManager.skip() only skips ads if IMA does not render the 'Skip ad' button.
     */
    skip(): void;

    /**
     * Start playing the ads.
     * Developers must call AdDisplayContainer.initialize() first.
     */
    start(): void;

    /**
     * Stop playing the ads. Calling this will get publisher back to the content.
     */
    stop(): void;

    /**
     * Updates the ads rendering settings.
     * This should be used specifically for VMAP use cases between ad breaks when ads rendering settings such as bitrate need to be updated.
     * @param adsRenderingSettings The updated ads rendering settings.
     */
    updateAdsRenderingSettings(adsRenderingSettings: AdsRenderingSettings): void;
};
//#endregion
