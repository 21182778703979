import { h, Component } from 'preact';
import { connect, useSelector } from 'react-redux';
import styles from './styles.css';

const SkipAdButton = (props) => {
    const { videoId, onSkip } = props;
    const currentAdPosition = useSelector((state) => state.currentAdPosition);
    const currentAdDuration = useSelector((state) => state.currentAdDuration);
    const isSkipButtonEnabled = useSelector((state) => state.isSkipButtonEnabled);
    const isAdMode = useSelector((state) => state.isAdMode);
    const skipTime = 15;

    if (!isAdMode || currentAdDuration <= skipTime || !isSkipButtonEnabled) return null;

    const timeLeft = Math.round(skipTime - currentAdPosition);
    const skipAvailable = currentAdPosition > skipTime || timeLeft <= 0;

    return (
        <button
            aria-label={'Skip ad in 15 seconds'}
            className={styles.adSkip}
            onClick={() => (skipAvailable ? onSkip() : null)}
        >
            <div className={styles.skipTxt}>Skip Ad</div>
            {skipAvailable && <div className={styles.videoSkipIcon}></div>}
            {!skipAvailable && <div className={styles.videoSkipCountdown}>{` in ${timeLeft}`}</div>}
        </button>
    );
};

function mapStateToProps(state) {
    return {
        isAdMode: state.isAdMode,
        currentAdPosition: state.currentAdPosition,
        currentAdDuration: state.currentAdDuration,
        isSkipButtonEnabled: state.isSkipButtonEnabled
    };
}

export default SkipAdButton;
