import { h, Component } from 'preact';
import { connect } from 'react-redux';
import fullScreenIcon from '../../../../svg/Icons_Full Screen_Inactive.svg?inline';
import exitFullScreenIcon from '../../../../svg/Icons_Full Screen_Active.svg?inline';

class FullScreenButton extends Component {
    shouldComponentUpdate(nextProps) {
        // only re render when these props change
        return this.props.isFullScreen !== nextProps.isFullScreen;
    }

    onKeyDown(e) {
        if (e.key === ' ') {
            e.stopPropagation();
            this.props.onToggleFullScreen();
        }
    }

    render() {
        const { isFullScreen, onToggleFullScreen } = this.props;
        const icon = isFullScreen ? exitFullScreenIcon : fullScreenIcon;
        return (
            <button
                className={'video-fullscreen f-r'}
                id={`video-fullscreen-${this.props.videoId}`}
                aria-pressed={isFullScreen}
                aria-label="Fullscreen"
                dangerouslySetInnerHTML={{ __html: icon }}
                onKeyDown={this.onKeyDown.bind(this)}
                onClick={onToggleFullScreen}
                data-testid="video-fullscreen-button"
            />
        );
    }
}

function mapStateToProps(reduxState) {
    return {
        isFullScreen: reduxState.isFullScreen
    };
}

const ConnectedFullScreenButton = connect(mapStateToProps)(FullScreenButton);

export default ConnectedFullScreenButton;
