import { h, FunctionalComponent } from 'preact';
import { useMemo } from 'preact/hooks';
import type { LegacyApiMediaItem } from '@video/common';
import { getSources } from '../../thumb-utils';

interface ResponsiveThumbnailProps {
    className?: string;
    componentRef?: any;
    height: number;
    highPriority?: boolean;
    id?: string;
    onLoad?: () => void;
    override?: string;
    videoData: LegacyApiMediaItem;
    width: number;
}

const ResponsiveThumbnail: FunctionalComponent<ResponsiveThumbnailProps> = ({
    componentRef,
    height,
    highPriority,
    override,
    videoData,
    width,
    ...props
}) => {
    const imgSources = useMemo(() => {
        if (override) {
            return { default: override };
        }

        if (!videoData) {
            return null;
        }

        const sources = getSources(videoData, width, height);
        if (!sources) {
            return null;
        }

        return {
            src: sources.default,
            srcSet: sources.ratios?.map(({ ratio, src }) => `${src} ${ratio}x`).join(', ')
        };
    }, [height, override, videoData, width]);

    if (!imgSources?.src) {
        return null;
    }

    return (
        <img
            {...props}
            {...imgSources}
            alt={videoData.name}
            // @ts-expect-error
            fetchpriority={highPriority ? 'high' : null}
            ref={componentRef}
            data-width={width}
            data-height={height}
        />
    );
};

export default ResponsiveThumbnail;
