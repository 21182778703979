import { h } from 'preact';
import { connect } from 'react-redux';
import { useEffect, useState, useRef } from 'preact/hooks';

import soundOnIcon from '../../../../../svg/Icons_Volume_Sound On.svg?inline';
import soundOffIcon from '../../../../../svg/Icons_Volume_Mute.svg?inline';

const VolumeControl = (props) => {
    const { videoId, isMuted, volume, onMuteUnMute, onSetVolume, onVolumeControlVisible } = props;
    const icon = isMuted ? soundOffIcon : soundOnIcon;
    const [mouseDown, setMouseDown] = useState(false);
    const [volPosition, setVolPosition] = useState(0);
    const volSliderRef = useRef(null);

    const onVolumeInteraction = (e) => {
        let vol = volume;
        let parentOffset = e.currentTarget.getBoundingClientRect();
        let x = e.pageX - parentOffset.left;
        vol = x / e.currentTarget.offsetWidth;
        onSetVolume(vol, true);
    };

    const onMouseUp = (e) => {
        setMouseDown(false);
        onVolumeInteraction(e);
    };

    const onMouseDown = (e) => {
        setMouseDown(true);
        onVolumeInteraction(e);
    };

    const onMouseMove = (e) => {
        if (mouseDown) {
            onVolumeInteraction(e);
        }
    };

    const onKeyDown = (e) => {
        let vol = volume;
        if (e.key === 'ArrowLeft' || e.key === 'ArrowDown') {
            e.stopPropagation();
            vol = e.shiftKey ? volume - 0.2 : volume - 0.1;
            onSetVolume(vol, true);
        } else if (e.key === 'ArrowRight' || e.key === 'ArrowUp') {
            e.stopPropagation();
            vol = e.shiftKey ? volume + 0.2 : volume + 0.1;
            onSetVolume(vol, true);
        }
    };

    const onFocusChange = (e) => {
        onVolumeControlVisible(e.type === 'focus');
    };

    const onRollOut = (e) => {
        setMouseDown(false);
        e.currentTarget.blur();
    };

    useEffect(() => {
        var w = volSliderRef.current.offsetWidth;
        if (w == 0) w = 80;
        setVolPosition(Math.round(w * volume));
    }, [volume]);

    return (
        <div className="video-volume f-l" id={`video-volume-${videoId}`}>
            <button
                className="video-volume-g-videoplayer"
                aria-label="Mute"
                aria-pressed={isMuted}
                type="button"
                id={`video-volume-g-${videoId}`}
                data-space="false"
                data-testid={`video-volume-button${isMuted ? '-muted' : ''}`}
                dangerouslySetInnerHTML={{ __html: icon }}
                onClick={onMuteUnMute}
                onFocus={onFocusChange}
                onBlur={onFocusChange}
                onMouseLeave={onRollOut}
            />
            <div
                className="video-volume-slider"
                id={`video-volume-slider-${videoId}`}
                ref={volSliderRef}
                onMouseUp={onMouseUp}
                onMouseDown={onMouseDown}
                onFocus={onFocusChange}
                onBlur={onFocusChange}
                onKeyDown={onKeyDown}
                onMouseLeave={onRollOut}
                onMouseMove={onMouseMove}
                aria-label="Volume up down"
                aria-valuenow={parseInt(volume * 100)}
                aria-valuetext={`Volume ${parseInt(volume * 100)}%`}
                tabindex="0"
                role="slider"
                aria-valuemin="0"
                aria-valuemax="100"
                data-testid="video-volume-slider"
            >
                <div id={`video-volume-b-${videoId}`} className="video-volume-b"></div>
                <div
                    id={`video-volume-v-${videoId}`}
                    style={{ width: volPosition + 'px' }}
                    className="video-volume-v"
                ></div>
                <div
                    id={`video-volume-h-${videoId}`}
                    style={{ left: volPosition + 'px' }}
                    className="video-volume-h"
                ></div>
            </div>
        </div>
    );
};

function mapStateToProps(state) {
    return {
        isMuted: state.isMuted,
        volume: state.volume
    };
}

const ConnectedVolumeControl = connect(mapStateToProps)(VolumeControl);

export default ConnectedVolumeControl;
