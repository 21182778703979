import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { isContentSaved, saveContent, deleteContent } from '../../../save-content-api';
import { showSavedModal } from '../../../actions';

import bookmarkIcon from '../../../../svg/Icons_Bookmark_Inactive.svg?inline';
import bookmarkIconOver from '../../../../svg/Icons_Bookmark_Active.svg?inline';
import { isExperimentRunning, sendOptimizelyEvent } from '../../../utils';

class SaveToQueueButton extends Component {
    constructor() {
        super();
        this.state = {
            isSaved: null,
            isSaving: false,
            isAvailable: false,
            isLoaded: false
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.videoData.guid !== this.props.videoData.guid) {
            this.setState({ isAvailable: false, isSaved: false, isLoaded: false, isSaved: false });
        }

        if (!this.props.isPlaybackInitializing || this.state.isLoaded) return;

        this.setState({ isLoaded: true });

        isContentSaved(this.props.videoData.guid, this.props.saveApiPublication)
            .then((isSaved) => {
                if (typeof isSaved === 'undefined') this.setState({ isAvailable: false, isSaved: false });
                else this.setState({ isAvailable: true, isSaved: isSaved });
            })
            .catch((err) => this.setState({ isSaving: false }));
    }

    saveToQueue() {
        if (this.state.isSaved) return;

        this.setState({ isSaved: true, isSaving: true });

        saveContent({ contentId: this.props.videoData.guid, pub: this.props.saveApiPublication, contentType: 'video' })
            .then(() => {
                this.setState({ isSaved: true, isSaving: false });
                this.props.showSavedModal();
            })
            .catch(() => this.setState({ isSaving: false }));
    }

    deleteFromQueue() {
        if (!this.state.isSaved) return;

        this.setState({ isSaved: false, isSaving: true });

        deleteContent(this.props.videoData.guid, this.props.saveApiPublication)
            .then(() => this.setState({ isSaved: false, isSaving: false }))
            .catch(() => this.setState({ isSaving: false }));
    }

    shouldComponentUpdate(nextProps, nextState) {
        return (
            this.state.isSaved !== nextState.isSaved ||
            this.props.isPlaybackInitializing !== nextProps.isPlaybackInitializing ||
            this.props.isAvailable !== nextProps.isAvailable
        );
    }

    handleClick() {
        if (this.state.isSaving) return;
        // TODO: Remove when experiment is over. I wasn't able to capture this click from the optimizely script so putting it here for now.
        if (isExperimentRunning('102p')) {
            sendOptimizelyEvent('102p_total_homepage_video_interactions');
        }
        if (isExperimentRunning('103p')) {
            sendOptimizelyEvent('103p_total_homepage_video_interactions');
        }

        this.props.onSaveChange(!this.state.isSaved);
        if (this.state.isSaved) {
            this.deleteFromQueue();
        } else {
            this.saveToQueue();
        }
    }

    render() {
        if (!this.state.isAvailable) return null;

        return (
            <button
                className={'video-save-queue f-r'}
                dangerouslySetInnerHTML={{ __html: this.state.isSaved === true ? bookmarkIconOver : bookmarkIcon }}
                onClick={this.handleClick.bind(this)}
                data-testid="video-save-button"
            />
        );
    }
}

function mapStateToProps(reduxState) {
    return { videoData: reduxState.videoData, isPlaybackInitializing: reduxState.isPlaybackInitializing };
}

const ConnectedSaveToQueueButton = connect(mapStateToProps, { showSavedModal })(SaveToQueueButton);

export default ConnectedSaveToQueueButton;
