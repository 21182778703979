import { h, Component } from 'preact';
import { connect } from 'react-redux';
import styles from './styles.css';

class LiveIndicator extends Component {
    render() {
        const { isCountdownVisible, isClickForSoundVisible, isThumbnailVisible, isLive } = this.props;

        if (
            this.props.isAdMode ||
            !this.props.isLive ||
            this.props.isCountdownVisible ||
            (this.props.isControlsVisible && !this.props.isClickForSoundVisible)
        )
            return null;

        return (
            <div id={`video-live-${this.props.videoId}`} className={styles.liveIndicatorWrapper}>
                <span className={styles.liveButton}>LIVE</span>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        isCountdownVisible: state.isCountdownVisible,
        isClickForSoundVisible: state.isClickForSoundVisible,
        isThumbnailVisible: state.isThumbnailVisible,
        isLive: state.videoData.state == 'live',
        isControlsVisible: state.isControlsVisible,
        isAdMode: state.isAdMode
    };
}

const ConnectedLiveIndicator = connect(mapStateToProps)(LiveIndicator);

export default ConnectedLiveIndicator;
