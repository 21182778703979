import type { LegacyApiMediaItem } from '@video/common';
import { Breakpoint } from 'src/types';

interface GetSourcesOutput {
    default?: string;
    ratios?: { ratio: number; src: string }[];
}

export function getSources(videoData: LegacyApiMediaItem, width: number, height: number): GetSourcesOutput | null {
    const ratios = [1.5, 2, 3];
    if (videoData.thumbnailImageManager) {
        const defaultSrc = `${videoData.thumbnailImageManager}?width=${width}&size=${
            videoData.aspectRatio ?? width / height
        }`;
        return {
            default: defaultSrc,
            ratios: ratios.map((ratio) => ({ ratio, src: `${defaultSrc}&pixel_ratio=${ratio}` }))
        };
    }

    if (!videoData.thumbnailList) {
        if (!videoData.thumbnailURL) {
            return null;
        }
        // no list so return the only image available
        return { default: videoData.thumbnailURL };
    }

    const { thumbnailList } = videoData;
    const aspect = width / height > 1 ? 1 : 0;
    // assuming the thumbnail list is sorted by width go through and find the best fit
    let index = thumbnailList.findIndex((image) => {
        if ((aspect == 1 && image.aspectRatio < 1) || (aspect == 0 && image.aspectRatio > 1)) return false;
        return image.width >= width;
    });

    if (index === -1) {
        // nothing was found so return the last one as a last resort
        return { default: thumbnailList[thumbnailList.length - 1].url };
    }

    const props: Required<GetSourcesOutput> = { default: thumbnailList[index].url, ratios: [] };

    // find a 2x image and add it to the srcset for high resolution screens
    let ratioIndex = 0;
    for (index = index + 1; index < thumbnailList.length; index++) {
        const image = thumbnailList[index];
        if ((aspect == 1 && image.aspectRatio < 1) || (aspect == 0 && image.aspectRatio > 1)) continue;

        if (image.width >= width * ratios[ratioIndex]) {
            props.ratios.push({ ratio: ratios[ratioIndex], src: image.url });
            ratioIndex++;
        }
    }

    return props;
}

export function getThumbWidth(breakpoint: Breakpoint) {
    switch (breakpoint) {
        case '16U':
        case '12U':
            return 1260;
        case '8U':
            return 860;
        case '4U':
            return 620;
        case 'tiny':
            return 350;
    }
}
